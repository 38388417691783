"use client";

import { useLocaleProvider } from "@/app/[lang]/LocaleProvider";
import { cn } from "@/utils/tw";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "./ui/formInputs/Select";
export function LocaleSelector({
  inverted = true
}: {
  inverted?: boolean;
}) {
  const {
    lang,
    setLanguage
  } = useLocaleProvider();
  return <Select onValueChange={setLanguage} defaultValue={lang} data-sentry-element="Select" data-sentry-component="LocaleSelector" data-sentry-source-file="LocaleSelector.tsx">
      <SelectTrigger className={cn("w-28", inverted ? "text-white" : "")} data-sentry-element="SelectTrigger" data-sentry-source-file="LocaleSelector.tsx">
        <SelectValue data-sentry-element="SelectValue" data-sentry-source-file="LocaleSelector.tsx" />
      </SelectTrigger>
      <SelectContent data-sentry-element="SelectContent" data-sentry-source-file="LocaleSelector.tsx">
        <SelectItem value="en" data-sentry-element="SelectItem" data-sentry-source-file="LocaleSelector.tsx">English</SelectItem>
        <SelectItem value="no" data-sentry-element="SelectItem" data-sentry-source-file="LocaleSelector.tsx">Norsk</SelectItem>
        <SelectItem value="dk" data-sentry-element="SelectItem" data-sentry-source-file="LocaleSelector.tsx">Dansk</SelectItem>
      </SelectContent>
    </Select>;
}